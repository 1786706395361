import React from "react";
import LocalizationModalLangItem from "./LocalizationModalLangItem";

const LocalizationModal = ({ defaultValue, propName, register, modalId }) => {
  return (
    <div className="modal fade" tabIndex="-1" aria-hidden="true" id={modalId}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            {/* <h5 className="modal-title fs-5">
              <input
                className="form-control"
                disabled
                {...register(propName + ".en")}
              />
            </h5> */}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <table className="table">
              <tbody>
                <LocalizationModalLangItem
                  code="tr"
                  name={propName + ".tr"}
                  register={register}
                />
                <LocalizationModalLangItem
                  code="de"
                  name={propName + ".de"}
                  register={register}
                />
                <LocalizationModalLangItem
                  code="es"
                  name={propName + ".es"}
                  register={register}
                />
                <LocalizationModalLangItem
                  code="fr"
                  name={propName + ".fr"}
                  register={register}
                />
                <LocalizationModalLangItem
                  code="it"
                  name={propName + ".it"}
                  register={register}
                />
                <LocalizationModalLangItem
                  code="ja"
                  name={propName + ".ja"}
                  register={register}
                />
                <LocalizationModalLangItem
                  code="ko"
                  name={propName + ".ko"}
                  register={register}
                />
                <LocalizationModalLangItem
                  code="hi"
                  name={propName + ".hi"}
                  register={register}
                />
                <LocalizationModalLangItem
                  code="ru"
                  name={propName + ".ru"}
                  register={register}
                />
                <LocalizationModalLangItem
                  code="arxa"
                  name={propName + ".arxa"}
                  register={register}
                />
                <LocalizationModalLangItem
                  code="zhchs"
                  name={propName + ".zhchs"}
                  register={register}
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocalizationModal;
