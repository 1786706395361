import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import config from "../../../helpers/config";
import { errorMessage } from "../../../helpers/form-helper";
import DisableCheckbox from "../../shared/DisableCheckbox";
import DisabledFormElement from "../../shared/DisabledFormElement";
import EditDeleteButton from "../../shared/EditDeleteButton";
import SaveButton from "../../shared/SaveButton";
import SubContainer from "../../shared/SubContainer";
import LocalizationButton from "../../shared/LocalizationButton";
import DefaultLangValue from "../../shared/DefaultLangValue";
import LocalizationModal from "../../shared/LocalizationModal";

const WordSaveAndList = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  const [wordList, setWordList] = useState([]);
  const [gameId, setGameId] = useState(props.gameId);
  const [resetWord, setResetWord] = useState();
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    getWords();
  }, []);

  useEffect(() => {
    reset({ name: { en: "" }, disabled: null });
  }, [resetWord]);

  const upload = (e) => {
    var uf = e.target.files[0];
    var fd = new FormData();
    fd.append("excel", uf, uf.name);
    setIsUploading(true);
    // toast.info("Yükleniyor...");

    const mid = toast.loading("Uploading...");

    axios
      .post(config.callUrl + "/word/savelist?gameId=" + props.gameId, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((data) => {
        toast.update(mid, {
          render: "Upload completed.",
          type: "success",
          isLoading: false,
          autoClose: true,
        });

        getWords();
      })
      .catch((err) => {
        toast.update(mid, {
          render: "Upload error.",
          type: "error",
          isLoading: false,
          autoClose: true,
        });

        // toast.error(error("Yükleme başarısız.", err));
        console.error(err);
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  const getWords = () => {
    axios
      .get(config.callUrl + "/word/get", {
        params: { active: true, gameId: gameId },
      })
      .then((response) => {
        // toast.success("Success");

        setWordList(response.data);
      })
      .catch((error) => {
        toast.error(error.response.data);
        console.error(error);
      });
  };

  const onSubmit = (data) => {
    data.game = gameId;
    axios
      .post(config.callUrl + "/word/save", data)
      .then((response) => {
        toast.success("Success");

        let list = [...wordList];
        const index = list.findIndex((a) => a._id === response.data._id);

        if (index !== -1) {
          list.splice(index, 1, response.data);
        } else {
          list.push(response.data);
        }
        setResetWord(!resetWord);
        setWordList(list);
      })
      .catch((error) => {
        console.error(error);
        toast.error(error.response.data);
      });
  };

  const onClickEditDelete = (type, id) => {
    if (type === "edit") {
      const word = wordList.find((a) => {
        return a._id === id;
      });
      reset();
      reset(word);
    } else if (type === "del") {
      axios
        .put(config.callUrl + "/word/delete/" + id)
        .then((response) => {
          toast.success("Success");

          const list = wordList.filter((i) => {
            return i._id !== id;
          });

          setWordList(list);
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.response.data);
        });
    }
  };

  const onDisabledChanged = (id, disabled) => {
    axios
      .put(config.callUrl + "/word/changedisabled/" + id, { d: disabled })
      .then((response) => {
        toast.success("Success");

        let list = [...wordList];

        list.forEach((i) => {
          if (i._id === id) {
            i.disabled = disabled;
            return false;
          }
        });

        setWordList(list);
      })
      .catch((error) => {
        console.error(error);
        toast.error(error.response.data);
      });
  };

  return (
    <SubContainer>
      <div>
        <div className="row justify-content-between">
          <div className="col-auto">
            <h6 style={{ display: "inline-block" }}>Game's Words</h6>{" "}
            <span className="text-danger">{wordList?.length || 0} words</span>
          </div>
          <div className="col-auto ">
            <div>
              <label className="upload-input-label" htmlFor="excel">
                <span className="btn btn-sm btn-outline-success">
                  <i className="bi bi-file-earmark-excel-fill"></i> Import from
                  Excel
                </span>
              </label>
              <input
                disabled={isUploading}
                className="d-none"
                type="file"
                onChange={upload}
                id="excel"
              />
            </div>
          </div>
        </div>
        <hr />

        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <table className="table">
            <thead>
              <tr>
                <th>English</th>
                <th>Other Langs</th>
                <th>Disable</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <DefaultLangValue name={"name.en"} register={register} />
                </td>
                <td>
                  <LocalizationButton modalId="wordLocModal" />
                </td>
                <td>
                  <DisabledFormElement register={register} />
                </td>
                <td className="text-end">
                  <SaveButton isSubmitting={isSubmitting} small={true} />
                  <a
                    className="btn btn-sm btn-outline-secondary ms-1"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setResetWord(!resetWord);
                    }}
                  >
                    Cancel
                  </a>
                </td>
              </tr>
              {wordList?.map((item) => {
                return (
                  <tr key={item._id}>
                    <td>{item.name.en}</td>
                    <td></td>
                    <td>
                      <DisableCheckbox
                        checked={item.disabled}
                        id={item._id}
                        onChange={onDisabledChanged}
                      />
                    </td>
                    <td className="text-end">
                      <EditDeleteButton
                        id={item._id}
                        onClick={onClickEditDelete}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <LocalizationModal
            modalId="wordLocModal"
            propName="name"
            defaultValue="name.en"
            register={register}
          />
        </form>
      </div>
    </SubContainer>
  );
};

export default WordSaveAndList;
