import React from "react";

export default function LocalizationButton({modalId}) {
  return (
    <a
      href="#"
      className="btn btn-outline-primary btn-sm me-2"
      title="Localization"
      data-bs-toggle="modal"
      data-bs-target={"#"+modalId}
    >
      <i className="bi bi-globe"></i>
    </a>
  );
}
