const config = {
  callUrl: "https://api.boomwords.com",
  // callUrl: "http://localhost:3002", //test

  // gameUrl: "https://ingilizce.fun/game-screen",
  gameUrl: "http://lus", //test

  rcSiteKey:"6LfqPC4qAAAAADe6zitl0hm64pAQkcokHToSVovV",
  // rcSiteKey: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI", //test
};

console.log(config.callUrl);
module.exports = config;
