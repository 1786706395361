import React from "react";

const DefaultLangValue = ({ name, register }) => {
  return (
    <input
      type="text"
      className="form-control"
      maxLength={1000}
      {...register(name, { maxLength:1000 })}
    />
  );
};

export default DefaultLangValue;
