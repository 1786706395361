import React from "react";

const LocalizationModalLangItem = ({ name, code, register }) => {
  return (
    <tr>
      <td className="w-25">{code}</td>
      <td>
        <input
          type="text"
          className="form-control"
          maxLength={1000}
          {...register(name, { maxLength:1000 })}
        />
      </td>
    </tr>
  );
};

export default LocalizationModalLangItem;
